import { useCallback, useEffect, useState } from "react";

import { tableStyles } from "./styles";
import { TableProps } from "./types";
import LoadingDots from "../LoadingDots";
import Typo from "../Typo";

import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface HorizontalTableProps extends TableProps {
  variant?: "project" | "default";
  noDataLabel?: string;
}

const HorizontalTable: React.FC<HorizontalTableProps> = (
  props: HorizontalTableProps
) => {
  const {
    size = "sm",
    stickyCols,
    stickyRows,
    zebra,
    classNames,
    columns,
    rows,
    infinitiLoad,
    onLoadMore = () => undefined,
    isLoading,
    page = 1,
    hideHeader = false,
    variant = "default",
    noDataLabel = "",
  } = props;
  const [observerTarget, setObserverTarget] = useState();
  const [localRow, setLocalRow] = useState<any[]>([]);
  const refObser = useCallback((node: any) => {
    if (node !== null) {
      setObserverTarget(node);
    }
  }, []);

  const layout = {
    project: {
      table: "max-h-[480px]",
      headerCell: "text-base font-normal px-3 py-2 whitespace-nowrap",
      bodyCell: "text-base font-normal px-3 py-3 whitespace-nowrap",
    },
    default: {
      table: "",
      headerCell: "text-sm font-normal",
      bodyCell: "text-sm font-normal",
    },
  };

  useEffect(() => {
    if (infinitiLoad && page > 1)
      return setLocalRow((pre: any) => [...(pre || []), ...rows]);
    setLocalRow(rows);
  }, [infinitiLoad, page, rows]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          onLoadMore();
        }
      },
      { threshold: 0.5 }
    );
    if (observerTarget) {
      observer.observe(observerTarget);
    }

    return () => {
      if (observerTarget) {
        observer.unobserve(observerTarget);
      }
    };
  }, [observerTarget, onLoadMore]);

  const checkRow = localRow?.filter(Boolean) || [];
  return (
    <div
      className={cn(
        "scroll table-wrapper overflow-x-auto lg:px-6",
        layout[variant].table,
        classNames?.wrapper
      )}
    >
      <table
        className={cn(
          "table",
          tableStyles.sizes[size],
          stickyCols && tableStyles.stickyCols,
          stickyRows && tableStyles.stickyRows,
          zebra && tableStyles.zebra,
          classNames?.table
        )}
      >
        {/* head */}
        {!hideHeader && (
          <thead>
            <tr
              className={cn(
                "sticky top-0 w-full overflow-hidden border-none bg-tableHeadingBackground text-tableHeadingText",
                classNames?.header
              )}
            >
              {columns.map((column) => {
                return (
                  <th
                    key={column.field}
                    className={cn(
                      "text-nowrap text-sm font-normal first:rounded-l-lg last:rounded-r-lg",
                      variant === "project" && "",
                      column?.headerCellClassName,
                      layout[variant].headerCell
                    )}
                  >
                    {column?.renderHeader
                      ? column.renderHeader?.(column)
                      : column.headerName}
                  </th>
                );
              })}
            </tr>
          </thead>
        )}
        <tbody className={cn(classNames?.body)}>
          <>
            {checkRow?.length > 0 &&
              checkRow.map((row, rowIdx) => {
                return (
                  <tr
                    className={cn(
                      "text-color [&:not(:last-child)]:border-tableRowDivider",
                      classNames?.row
                    )}
                    key={row?.id || rowIdx}
                    ref={
                      infinitiLoad && rowIdx === checkRow.length - 1
                        ? refObser
                        : null
                    }
                  >
                    {columns.map((col, colIdx) => {
                      const field = col.field;
                      return (
                        <td
                          key={`${field}-${colIdx}`}
                          className={cn(
                            "text-nowrap text-sm font-normal",
                            layout[variant].bodyCell,
                            typeof col.cellClassName === "function"
                              ? col.cellClassName(row)
                              : col.cellClassName
                          )}
                          style={col.cellStyle}
                          onClick={() =>
                            typeof col?.onClickCell === "function" &&
                            col?.onClickCell(row, col)
                          }
                        >
                          {col.renderCell
                            ? col.renderCell(row, col, rowIdx)
                            : row[field]}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            {checkRow?.length === 0 && !isLoading && noDataLabel && (
              <tr>
                <td colSpan={columns.length} className="text-center">
                  <Typo
                    variant={ETypoVariant.BODY_TITLE_16}
                    tag={ETypoTag.SPAN}
                    color={ETypoColor.TEXT}
                    className="opacity-75"
                  >
                    {noDataLabel}
                  </Typo>
                </td>
              </tr>
            )}

            {isLoading && (
              <tr>
                <td colSpan={columns.length}>
                  <LoadingDots />
                </td>
              </tr>
            )}
          </>
        </tbody>
      </table>
    </div>
  );
};

export default HorizontalTable;
